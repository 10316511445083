const mode = "DEVLOPMENT"; // PRODUCTION

const BASE_URL =
  mode === "PRODUCTION"
    ? "https://api.qms.vytech.co"
    : "https://api.qms.vytech.co";

module.exports = {
  BASE_URL,
};
