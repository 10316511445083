import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import GlegooBold from "../../assets/fonts/Glegoo/Glegoo-Bold.ttf";
import GlegooRegular from "../../assets/fonts/Glegoo/Glegoo-Regular.ttf";
import GilroyBold from "../../assets/fonts/gilroy/Gilroy-Bold.ttf";
import GilroySemiBold from "../../assets/fonts/gilroy/Gilroy-SemiBold.ttf";
import GilroyMedium from "../../assets/fonts/gilroy/Gilroy-Medium.ttf";
import GilroyRegular from "../../assets/fonts/gilroy/Gilroy-Regular.ttf";
import barcode from "../../assets/NewImage/bar.png";
import sigen2 from "../../assets/images/sigen2.png";
import demoSign from "../../assets/images/sign2.png";
import sigen1 from "../../assets/images/sign1.png";
import sigen3 from "../../assets/images/sigen3.png";
import leftArrow from "../../assets/images/leftArrow.png";
import rightArrow from "../../assets/images/rightArrow.png";

Font.register({
  family: "Glegoo-Regular",
  src: GlegooRegular,
});

Font.register({
  family: "Glegoo-Bold",
  src: GlegooBold,
});
Font.register({
  family: "Gilroy-Regular",
  src: GilroyRegular,
});
Font.register({
  family: "Gilroy-Medium",
  src: GilroyMedium,
});
Font.register({
  family: "Gilroy-Bold",
  src: GilroyBold,
});
Font.register({
  family: "Gilroy-SemiBold",
  src: GilroySemiBold,
});

const styles = StyleSheet.create({
  barcode: {
    width: "68px",
    height: "68px",
  },
  sign1: {
    width: "62px",
    height: "41px",
  },
  sign2: {
    width: "87px",
    height: "39px",
  },
  sign3: {
    width: "79px",
    height: "33px",
  },
  boxes: {
    flex: 1,
    fontFamily: "Gilroy-Medium",
    fontSize: "8px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "90px",
    height: "90px",
    borderRight: "0.5px solid #E9E9ED",
  },
  boxesLast: {
    flex: 1,
    fontFamily: "Gilroy-Medium",
    fontSize: "8px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "90px",
    height: "90px",
  },
  titleText: {
    flex: 1,
    fontFamily: "Gilroy-Medium",
    fontSize: "8px",
    textAlign: "center",
  },
  endReportText: {
    margin: "2px 0 0 10px",
    fontFamily: "Gilroy-SemiBold",
    fontSize: "7px",
  },
});

const Footer = () => {
  return (
    <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
      <View
        style={{
          flexDirection: "row",
          marginTop: "50px",
          margin: "5px 10px 0 10px",
        }}
      >
        <Text style={styles.titleText}>QR CODE Sticker</Text>
        <Text style={styles.titleText}>Tested By</Text>
        <Text style={styles.titleText}>Checked By</Text>
        <Text style={styles.titleText}>Approved By</Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          border: "0.5px solid #E9E9ED",
          margin: "0 15px 0 15px",
        }}
      >
        <View style={styles.boxes}>
          <Image src={barcode} style={styles.barcode} />
          <Text
            style={{
              fontFamily: "Gilroy-Medium",
              fontSize: "5px",
              textAlign: "center",
              paddingTop: 3,
            }}
          >
            Vytech Qms
          </Text>
        </View>
        <View style={styles.boxes}>
          <Image src={demoSign} style={styles.sign2} />
        </View>
        <View style={styles.boxes}>
          <Image src={demoSign} style={styles.sign1} />
        </View>
        <View style={styles.boxesLast}>
          <Image src={demoSign} style={styles.sign3} />
        </View>
      </View>
      <View style={{ marginTop: "10px" }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100px",
            margin: "auto",
          }}
        >
          <View>
            <Image src={leftArrow} style={{ width: "12px" }} />
          </View>
          <Text
            style={{
              fontFamily: "Gilroy-Bold",
              fontSize: "8px",
              textAlign: "center",
              marginTop: "3px",
            }}
          >
            End of the Report
          </Text>
          <View>
            <Image src={rightArrow} style={{ width: "12px" }} />
          </View>
        </View>
      </View>
      <View style={{ marginTop: 10 }}>
        <Text style={styles.endReportText}>
          1. The remarks given of this report based on the available information
          in service request form submitted.
        </Text>
        <Text style={styles.endReportText}>
          2. The test report and result relate to the particular
          specimen/sample(s) of the material as delivered/received and at the
          time of tested in the laboratory.
        </Text>
        <Text style={styles.endReportText}>
          3. This report may not be reproduced in part, without the permission
          of laboratory.
        </Text>
        <Text style={styles.endReportText}>
          4. Any correction invalid this test.
        </Text>
      </View>
      {/* <View
                style={{ borderBottom: "1px solid #050340", marginTop: 10 }}
              />
              <View>
                <Text
                  style={{
                    color: "#2B2A28",
                    fontFamily: "Gilroy-Medium",
                    fontSize: "8px",
                    margin: "5px 0 0 5px",
                  }}
                >
                  Registered Office :
                  <Text
                    style={{
                      color: "#2B2A28",
                      fontFamily: "Gilroy-Bold",
                      fontSize: "8px",
                    }}
                  >
                    235, OPP-STAR BAZAR, SOMESHWARA COMPLEX-II, SATELLITE ROAD,
                    SATELLITE, Ahmedabad-380015 (Gujarat)
                  </Text>
                </Text>
                <Text
                  style={{
                    color: "#2B2A28",
                    fontFamily: "Gilroy-Medium",
                    fontSize: "8px",
                    margin: "5px 0 0 5px",
                  }}
                >
                  MFG at :
                  <Text
                    style={{
                      color: "#2B2A28",
                      fontFamily: "Gilroy-Bold",
                      fontSize: "8px",
                    }}
                  >
                    Survey No. 101 (Part B) T.P. 217, Opp. jay & Rahul Farm, K.
                    K. Patel Farm Road, Shilaj, Ahmedabad - 380059 (Gujarat)
                  </Text>
                </Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "5px",
                  }}
                >
                  <Text
                    style={{
                      color: "#2B2A28",
                      fontFamily: "Gilroy-Medium",
                      fontSize: "8px",
                      margin: "0 0 0 5px",
                    }}
                  >
                    E :
                    <Text
                      style={{
                        color: "#2B2A28",
                        fontFamily: "Gilroy-Bold",
                        fontSize: "8px",
                      }}
                    >
                      inquiry@pinalsand.com
                    </Text>
                  </Text>

                  <Text
                    style={{
                      color: "#2B2A28",
                      fontFamily: "Gilroy-Bold",
                      fontSize: "8px",
                      marginLeft: "5px",
                    }}
                  >
                    |
                  </Text>
                  <Text
                    style={{
                      color: "#2B2A28",
                      fontFamily: "Gilroy-Medium",
                      fontSize: "8px",
                      margin: "0 0 0 5px",
                    }}
                  >
                    W :
                    <Text
                      style={{
                        color: "#2B2A28",
                        fontFamily: "Gilroy-Bold",
                        fontSize: "8px",
                      }}
                    >
                      www.pinalsand.com
                    </Text>
                  </Text>
                  <Text
                    style={{
                      color: "#2B2A28",
                      fontFamily: "Gilroy-Bold",
                      fontSize: "8px",
                      marginLeft: "5px",
                    }}
                  >
                    |
                  </Text>
                  <Text
                    style={{
                      color: "#2B2A28",
                      fontFamily: "Gilroy-Medium",
                      fontSize: "8px",
                      margin: "0 0 0 5px",
                    }}
                  >
                    P :
                    <Text
                      style={{
                        color: "#2B2A28",
                        fontFamily: "Gilroy-Bold",
                        fontSize: "8px",
                      }}
                    >
                      +91 98250 84500 , +91 98250 79433
                    </Text>
                  </Text>
                </View>
              </View> */}
      <View
        style={{
          borderTop: "1px solid #050340",
          marginTop: "10px",
        }}
      >
        <View
          style={{
            padding: "0px 6px",
            fontSize: "8px",
            color: "#2B2A28",
            fontFamily: "Gilroy-Medium",
            display: "flex",
            rowGap: "4px",
            margin: "6px 0",
          }}
        >
          {/*  <View
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text>Registered Office : </Text>
            <Text style={{ fontFamily: "Gilroy-Bold" }}>
              235, OPP-STAR BAZAR, SOMESHWARA COMPLEX-II, SATELLITE ROAD,
              SATELLITE, Ahmedabad-380015 (Gujarat)
            </Text>
          </View> */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text>MFG at: </Text>
            <Text style={{ fontFamily: "Gilroy-Bold" }}>
              510, Addor Aspire, B/s Jhanvee Restaurant, Nr. Passport Office,
              Polytechnic Rd, Ahmedabad, Gujarat 380015
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              // marginTop: "5px",
            }}
          >
            <Text
              style={{
                color: "#2B2A28",
                fontFamily: "Gilroy-Medium",
                fontSize: "8px",
                // margin: "0 0 0 5px",
              }}
            >
              E :
              <Text
                style={{
                  color: "#2B2A28",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "8px",
                }}
              >
                inquiry.vytechenterprise@gmail.com
              </Text>
            </Text>

            <Text
              style={{
                color: "#2B2A28",
                fontFamily: "Gilroy-Bold",
                fontSize: "8px",
                marginLeft: "5px",
              }}
            >
              |
            </Text>
            <Text
              style={{
                color: "#2B2A28",
                fontFamily: "Gilroy-Medium",
                fontSize: "8px",
                margin: "0 0 0 5px",
              }}
            >
              W :
              <Text
                style={{
                  color: "#2B2A28",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "8px",
                }}
              >
                www.qms.vytech.co
              </Text>
            </Text>
            <Text
              style={{
                color: "#2B2A28",
                fontFamily: "Gilroy-Bold",
                fontSize: "8px",
                marginLeft: "5px",
              }}
            >
              |
            </Text>
            <Text
              style={{
                color: "#2B2A28",
                fontFamily: "Gilroy-Medium",
                fontSize: "8px",
                margin: "0 0 0 5px",
              }}
            >
              P :
              <Text
                style={{
                  color: "#2B2A28",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "8px",
                }}
              >
                +91 9898888180
              </Text>
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Footer;
