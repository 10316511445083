import {
  Document,
  Font,
  PDFDownloadLink,
  PDFViewer,
  Page,
  StyleSheet,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GlegooBold from "../assets/fonts/Glegoo/Glegoo-Bold.ttf";
import GlegooRegular from "../assets/fonts/Glegoo/Glegoo-Regular.ttf";
import GilroyBold from "../assets/fonts/gilroy/Gilroy-Bold.ttf";
import GilroyMedium from "../assets/fonts/gilroy/Gilroy-Medium.ttf";
import GilroyRegular from "../assets/fonts/gilroy/Gilroy-Regular.ttf";
import GilroySemiBold from "../assets/fonts/gilroy/Gilroy-SemiBold.ttf";
import CertificateGradeASTM2 from "./certificate/CertificateGradeASTM2.js";
import CertificateGradeASTMc from "./certificate/CertificateGradeASTMc.js";
import CertificateGradeASTMd from "./certificate/CertificateGradeASTMd.js";
import CertificateGradeBS13 from "./certificate/CertificateGradeBS13.js";
import CertificateGradeBSEN from "./certificate/CertificateGradeBSEN.js";
import CertificateGradeDS from "./certificate/CertificateGradeDS.js";
import CertificateGradeI from "./certificate/CertificateGradeI.js";
import CertificateGradeII from "./certificate/CertificateGradeII.js";
import CertificateGradeIII from "./certificate/CertificateGradeIII.js";
import { BASE_URL } from "./web-config.js";

Font.register({
  family: "Glegoo-Regular",
  src: GlegooRegular,
});

Font.register({
  family: "Glegoo-Bold",
  src: GlegooBold,
});
Font.register({
  family: "Gilroy-Regular",
  src: GilroyRegular,
});
Font.register({
  family: "Gilroy-Medium",
  src: GilroyMedium,
});
Font.register({
  family: "Gilroy-Bold",
  src: GilroyBold,
});
Font.register({
  family: "Gilroy-SemiBold",
  src: GilroySemiBold,
});

const CommanCertificate = () => {
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  let certificateName = "";
  const { batchNumber } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/batch/bag/${encodeURIComponent(batchNumber)}`
        );

        if (!response.ok) {
          // Handle unauthorized error
          if (response.status === 401) {
            console.error("Unauthorized: Invalid token or expired");
            // Perform actions for unauthorized error, such as redirecting to login
          } else {
            console.error(`Error: ${response.status} - ${response.statusText}`);
          }
          return;
        }
        const data = await response.json();
        if (data.status === "200") {
          setApiData(data);
        } else {
          setError("DATA NOT FOUND");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (error) {
    return (
      <div className="w-full h-screen mx-auto flex items-center justify-center">
        <p className="text-lg">{error}</p>
      </div>
    );
  }

  const propsData = apiData && apiData.data && apiData.data;

  if (isLoading) {
    return (
      <div className=" w-full h-screen mx-auto flex items-center justify-center">
        <p className="text-lg">Please wait...</p>
      </div>
    ); // Replace with your preferred loading message or spinner
  }
  const certificationType = propsData && propsData.type ? propsData.type : "";

  let certificationComponent;

  switch (certificationType) {
    case "Standard_sand_grade_I":
      certificationComponent = <CertificateGradeI propsData={propsData} />;
      break;
    case "Standard_sand_grade_II":
      certificationComponent = <CertificateGradeII propsData={propsData} />;
      break;
    case "Standard_sand_grade_III":
      certificationComponent = <CertificateGradeIII propsData={propsData} />;
      break;
    case "Bsen196_iso679_V":
      certificationComponent = <CertificateGradeBSEN propsData={propsData} />;
      break;
    case "Astm_c778_grade_sand_VI":
      certificationComponent = <CertificateGradeASTMc propsData={propsData} />;
      break;
    case "Astm_20_30_sand_VII":
      certificationComponent = <CertificateGradeASTM2 propsData={propsData} />;
      break;
    case "Standard_Density_sand_IV":
      certificationComponent = <CertificateGradeDS propsData={propsData} />;
      break;
    case "Bs_1377_density_sand_VIII":
      certificationComponent = <CertificateGradeBS13 propsData={propsData} />;
      break;
    case "Astm_d1556m_15_density_sand_IX":
      certificationComponent = <CertificateGradeASTMd propsData={propsData} />;
      break;
    default:
    // tableComponent = null;
  }

  const RenderComponent = () => {
    return (
      <Document>
        <Page size="A4">{certificationComponent}</Page>
      </Document>
    );
  };

  const isMobile = window.innerWidth <= 768; // Adjust the width threshold as needed

  return (
    <>
      {isMobile ? (
        <PDFDownloadLink
          document={<RenderComponent />}
          fileName={`${batchNumber}.pdf`}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "200px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "200px",
            margin: "auto",
            marginTop: "auto",
            backgroundColor: "#00205e",
            color: "white",
            padding: "10px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          {({ loading }) => (loading ? "Loading document..." : "Download now!")}
        </PDFDownloadLink>
      ) : (
        <PDFViewer style={{ width: "100%", height: "900px" }}>
          <RenderComponent />
        </PDFViewer>
      )}
    </>
  );
};

export default CommanCertificate;
