import React, { useEffect, useRef, useState } from "react";
import ScanIcon from "../assets/images/ScanIcon.svg";
import searchSvgIcon from "../assets/images/SearchIconSvg.svg";
import bgImage from "../assets/images/bg.png";
import heart from "../assets/images/heart.svg";
// import logo from "../assets/images/leftsideimg.png";
import logo from "../assets/NewImage/vytech-logo.svg";
import jsQR from "jsqr";

const Home = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef();

  const handleSearch = (e) => {
    const { value } = e.target;
    setErrorMessage("");
    console.log(value);
    setSearchTerm(value);
    if (value.trim() === "") {
      setErrorMessage("Please enter a test report number.");
      return;
    }
  };

  const handleSubmit = () => {
    if (!searchTerm) {
      setErrorMessage("Please enter a test report number.");
      return false;
    }
    setErrorMessage("");

    const decodedLastPart = encodeURIComponent(searchTerm);
    const url = `/view-certificate/${encodeURIComponent(decodedLastPart)}`;
    window.open(url, "_blank");
    return true;
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const imageUrl = URL.createObjectURL(file);
        const img = new Image();
        img.src = imageUrl;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, img.width, img.height);

          const imageData = ctx.getImageData(0, 0, img.width, img.height);
          const code = jsQR(imageData.data, img.width, img.height);

          if (code) {
            const parts = code.data.replace(/"/g, "").split("/");
            const lastPart = parts.pop();
            const decodedLastPart = decodeURIComponent(lastPart);
            const url = `/view-certificate/${encodeURIComponent(
              decodedLastPart
            )}`;
            window.open(url, "_blank");
          } else {
            alert("No QR Code found in the image.");
          }
        };
      } catch (error) {
        console.error("Error reading QR Code:", error);
      }
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url('${bgImage}')`,
      }}
      className={`flex flex-col items-center justify-center px-2 xl:px-0 lg:px-0 min-h-screen bg-no-repeat bg-cover`}
    >
      <div
        className="flex m-auto flex-col items-center justify-center mx-3
        w-full"
      >
        {/* sm:bg-red-400
      md:bg-green-400
      lg:bg-yellow-400
      xl:bg-pink-400  */}
        <img src={logo} className="m-auto w-30" />
        <h3 className="mt-3">Your Reliable Partner in Quality.</h3>

        <div className="bg-white border-gray-400 p-2 sm:p-3 px-3 flex rounded-lg gap-x-2 mx-3 mt-5 max-w-2xl w-full ">
          <img src={searchSvgIcon} className="sm:w-[15px] lg:w-auto" />

          <input
            onChange={handleSearch}
            type="text"
            className="p-2 lg:p-3 xl:p-3  px-1 border-none w-full outline-none text-[12px] lg:text-[16px] xl:text-[16px]"
            placeholder="Enter test report no or scan qr Code"
          />
          <label className="qrcode-text-btn">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              onClick={(event) => {
                event.currentTarget.value = null;
              }}
              ref={inputRef}
            />
            <img src={ScanIcon} />
          </label>

          <button
            onClick={handleSubmit}
            className="border-none bg-[#0E457E] text-[12px] lg:text-[15px] text-white rounded-md  xl:px-6 lg:px-6 sm:px-5 px-3 lg:my-1.5 my-0 w-auto "
          >
            Search
          </button>
        </div>
        <p
          className={`text-red-500 text-justify my-2 text-[14px] min-h-[22px]`}
        >
          {errorMessage}
        </p>
      </div>
      <h3 className=" py-2 text-[12px] xl:text-[15px] lg:text-[15px]  flex items-center gap-x-1">
        Crafted with <img src={heart} /> by
        <a href="https://www.vytechenterprise.com" target="_blank">
          Vytech Enterprise
        </a>
      </h3>
    </div>
  );
};

export default Home;
